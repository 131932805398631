import React from 'react';
import {
  Link
} from 'gatsby';
import {
  Image,
  Container,
  Grid,
  Box,
  Button
} from 'theme-ui';

import vizioLogo from '../images/VIZIO__Beyond_the_Glass.png';

function Header(props) {
  return (
    <Box
      sx={{
        pt: 3,
        zIndex: 9
      }}>
      <Container
        sx={{
          padding: ['0 1rem', null, '0 2rem']
        }}>
        <Grid
          gap={2}
          columns={2}
          p={2}
          sx={{
            padding: '0.5rem 0',
            alignItems: 'center'
          }}>
          <Box>
            <Link to='/'>
              <Image
                src={vizioLogo}
                sx={{
                  width: [100, null, 150]
                }}
              />
            </Link>
          </Box>
          <Box
            sx={{
              justifySelf: 'end',
              display: props.showCTA ? 'block' : 'none'
            }}>
            <Button
              variant='primary'
              onClick={props.openModal}
              sx={{
                fontSize: 2,
                display: ['none', null, 'block']
              }}>
              Register for event
            </Button>
            <Button
              variant='primary'
              onClick={props.openModal}
              sx={{
                fontSize: 2,
                display: ['block', null, 'none']
              }}>
              Register
            </Button>
          </Box>
          <Box
            sx={{
              justifySelf: 'end',
              display: props.showContactCTA ? 'block' : 'none'
            }}>
            <Button
              variant='primary'
              onClick={props.openContactModal}
              sx={{
                fontSize: 2,
                display: ['none', null, 'block']
              }}>
              Get in Touch
            </Button>
            <Button
              variant='primary'
              onClick={props.openContactModal}
              sx={{
                fontSize: 1,
                padding: '1rem 1.5rem',
                display: ['block', null, 'none']
              }}>
              Get in Touch
            </Button>
          </Box>
        </Grid>
      </Container>
    </Box>
  )
}

export default Header;
